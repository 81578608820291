import React from 'react'
import Layout from 'src/layouts/BaseLayout'

import HeroNewApp from './sections/HeroNewApp/_index'
import VejaComoEsimples from './sections/veja-como-e-simples/_index'
import JaCriouSuaConta from './sections/ja-criou-sua-conta/_index'
import DisclamerUsend from './sections/disclamer/_index'
import AppSeraDesativado from './sections/app-sera-desativado/_index'
import NossaMelhorTaxa from './sections/nossa-melhor-taxa/_index'

import pageContext from './pageContext.json'

const Hero = () => {
  const lang = 'pt'

  return (
    <Layout pageContext={pageContext}>
      <HeroNewApp lang={lang} />
      <VejaComoEsimples lang={lang} />
      <JaCriouSuaConta lang={lang} />
      <DisclamerUsend lang={lang} />
      <AppSeraDesativado lang={lang} />
      <NossaMelhorTaxa lang={lang} />
    </Layout>
  )
}

export default Hero
